#bgvidmain {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: 0;

    //opacity: 0.7;
    @media #{$md-layout} {
        width: auto;
        height: 100%;
    }

    @media #{$sm-layout} {
        width: auto;
        height: 100%;
    }

    @media #{$lg-layout} {
        width: 100%;
        height: auto;
    }

    @media #{$laptop-device} {
        width: 100%;
        height: auto;
    }
}


#video-container:after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #0f1524, rgba(0, 0, 0, 0));
    opacity: 1;
    z-index: 0;
}

#bgvidcity {
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 1;
    top: 0;
}

#bgvidabout {
    width: 100%;
    max-width: 300px;
    height: auto;
    position: absolute;
    opacity: 1;
    top: 0;
    border-radius: 10px;

    @media #{$md-layout} {
        display: none;
    }

    @media #{$sm-layout} {
        display: none;
    }

}

.pixelpark-logo {
    max-width: 175px;
}

.respbreak {
    @media #{$lg-layout} {
        display: none;
    }
}

.white {
    color: #fff;
}

#pcform {
    display: none;
}

.active-dark {
    .single-tab-content {
        p {
            max-width: 600px;
            color: #fff;

        }
    }
}

.single-tab-content .social-share {
    a span {
        position: absolute;
        margin-left: 30px;
        font-weight: 400;
        font-size: 17px;
        color: #fff;
    }
}

// modal

.ReactModal__Overlay {
    background: rgba(37, 17, 73, 0.8) !important;
    //background: linear-gradient(356deg, rgba(47,24,108,0.95) 0%, rgba(97,27,166,0.95) 38%, rgba(92,25,157,0.95) 65%, rgba(61,20,131,0.95) 100%);
    z-index: 10000000000;

    .ReactModal__Content {
        background: rgb(47, 24, 108) !important;
        background: linear-gradient(356deg, rgba(47, 24, 108, 0.95) 0%, rgba(97, 27, 166, 0.95) 38%, rgba(92, 25, 157, 0.95) 65%, rgba(61, 20, 131, 0.95) 100%) !important;
        border: 0 !important;
        padding: 50px !important;
        border-radius: 10px !important;
        color: #fff;

        @media #{$sm-layout} {
            padding: 20px !important;
            height: 100% !important;
            overflow-y: auto;
            max-width: 90vw !important;
        }

        h2 {
            color: #fff;
        }

        img {
            border-radius: 10px;
        }


    }
}


.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}